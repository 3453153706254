import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// -----------------------------------------------------------------------

Logo.propTypes = {
	disabledLink: PropTypes.bool,
	sx: PropTypes.object,
}
export default function Logo({ disabledLink = false, sx }) {
	const theme = useTheme();
	const PRIMARY_LIGHT = theme.palette.primary.light;
	const PRIMARY_MAIN = theme.palette.primary.main;
	const PRIMARY_DARK = theme.palette.primary.dark;
	const logo = (
		<Box sx={{ width: 40, height: 40, ...sx }}>
			<svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%"  viewBox="0 0 589.000000 615.000000">
				<defs>
					<linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
						<stop offset="0%" stopColor={PRIMARY_DARK} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
					<linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
						<stop offset="0%" stopColor={PRIMARY_LIGHT} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
					<linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
						<stop offset="0%" stopColor={PRIMARY_LIGHT} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
				</defs>
				<g
					fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd" transform="translate(0.000000,615.000000) scale(0.100000,-0.100000)">


<path d="M659 5773 c0 -10 -1 -432 -1 -938 l-1 -920 74 -110 c40 -60 76 -112
79 -115 3 -3 21 -31 40 -62 19 -32 38 -58 42 -58 5 0 34 39 64 88 31 48 82
125 113 172 l56 85 5 760 5 760 50 8 c27 5 435 7 905 4 754 -3 866 -5 950 -21
114 -20 257 -53 285 -65 11 -5 58 -22 105 -38 129 -43 360 -163 489 -255 135
-97 339 -294 429 -415 165 -224 282 -477 336 -728 22 -102 44 -289 46 -375 2
-171 -42 -426 -107 -619 -52 -156 -185 -400 -295 -541 -179 -230 -465 -456
-724 -571 -38 -17 -82 -36 -99 -44 -61 -27 -214 -76 -310 -99 -205 -50 -240
-51 -1185 -51 l-885 0 -5 962 c-3 529 -8 964 -11 967 -3 3 -17 -11 -31 -32
-13 -20 -57 -86 -97 -145 -39 -60 -77 -107 -84 -104 -7 2 -56 68 -109 148 -54
79 -105 145 -113 147 -13 3 -15 -127 -15 -1132 0 -724 4 -1137 10 -1141 10 -6
4481 -7 4487 -1 6 6 4 4477 -2 4487 -4 5 -877 9 -2251 9 -2130 0 -2244 -1
-2245 -17z"/>
<path d="M2685 5079 c-4 -5 -1 -57 6 -115 22 -187 -2 -474 -50 -604 -32 -86
-63 -162 -87 -210 -32 -67 -133 -220 -145 -220 -5 0 -9 -6 -9 -13 0 -10 -59
-74 -158 -168 -31 -29 -127 -96 -170 -118 -63 -32 -58 -40 52 -96 311 -157
535 -461 638 -865 19 -74 22 -113 23 -305 0 -210 -1 -225 -29 -338 -16 -64
-26 -120 -22 -124 6 -6 65 7 181 40 72 20 221 97 333 171 208 138 372 324 499
566 88 167 154 371 183 565 14 94 8 436 -9 535 -112 643 -545 1139 -1122 1285
-113 29 -106 28 -114 14z"/>
<path d="M1253 1141 c-58 -15 -114 -61 -148 -121 -27 -49 -28 -162 0 -210 44
-78 133 -130 220 -130 89 0 188 57 225 131 24 45 27 143 7 192 -20 47 -64 95
-109 118 -43 22 -146 33 -195 20z m148 -124 c19 -13 40 -39 48 -61 13 -33 13
-44 0 -79 -30 -88 -143 -115 -209 -52 -58 55 -49 156 18 198 39 24 102 22 143
-6z"/>
<path d="M1830 1144 c-94 -38 -137 -76 -163 -142 -26 -64 -22 -130 11 -193 48
-92 139 -136 258 -124 82 9 147 57 186 135 23 46 26 63 21 105 -16 137 -93
211 -226 220 -39 3 -79 2 -87 -1z m129 -118 c41 -22 61 -59 61 -112 0 -59 -28
-100 -81 -115 -110 -33 -203 71 -155 173 28 59 114 85 175 54z"/>
<path d="M4339 1143 c-15 -4 -62 -93 -112 -213 -10 -25 -36 -83 -58 -129 -22
-47 -37 -91 -35 -98 4 -8 23 -13 53 -13 40 0 53 5 73 28 22 25 33 28 96 30 70
3 72 2 94 -27 19 -27 27 -31 70 -31 27 0 52 5 55 11 4 5 -1 27 -10 47 -10 20
-30 64 -46 97 -15 33 -50 110 -78 170 -50 109 -71 136 -102 128z m39 -257 c18
-37 9 -56 -28 -56 -33 0 -36 9 -19 59 13 37 28 36 47 -3z"/>
<path d="M684 1127 c-2 -7 -3 -107 -2 -222 l3 -210 107 -3 c86 -2 114 1 145
15 45 22 83 75 83 117 0 37 -30 90 -60 106 -15 8 -19 17 -15 28 31 74 18 126
-38 160 -29 18 -50 22 -126 22 -65 0 -93 -4 -97 -13z m160 -93 c23 -9 20 -51
-5 -64 -25 -14 -49 2 -49 35 0 34 17 43 54 29z m23 -155 c55 -21 51 -63 -8
-80 -46 -14 -69 -1 -69 40 0 47 25 60 77 40z"/>
<path d="M2242 918 l3 -223 50 0 50 0 6 72 c5 65 13 94 22 82 2 -2 25 -35 52
-72 26 -37 52 -66 58 -64 6 2 29 31 51 65 52 78 70 78 73 -3 4 -84 5 -87 60
-83 l48 3 3 223 2 222 -22 0 c-17 0 -40 -24 -93 -96 -38 -52 -82 -110 -98
-127 l-27 -32 -23 26 c-12 14 -55 72 -96 127 -58 80 -79 102 -98 102 l-23 0 2
-222z"/>
<path d="M2835 1128 c-3 -7 -4 -108 -3 -223 l3 -210 115 0 c99 0 121 4 157 23
78 41 118 117 111 217 -5 79 -41 137 -108 175 -39 21 -60 25 -157 28 -89 3
-114 1 -118 -10z m230 -122 c28 -18 47 -76 38 -117 -11 -57 -111 -110 -148
-79 -20 16 -22 189 -2 209 15 16 79 9 112 -13z"/>
<path d="M3314 1127 c-2 -7 -3 -107 -2 -222 l3 -210 50 0 50 0 3 55 c3 76 10
100 27 100 8 0 15 -4 15 -8 0 -5 21 -41 46 -80 l46 -72 54 0 c68 0 70 14 14
99 -44 66 -48 82 -24 102 47 39 58 131 22 181 -35 49 -88 68 -201 68 -70 0
-99 -4 -103 -13z m204 -114 c16 -23 15 -30 -8 -53 -46 -46 -108 -4 -81 56 10
23 16 26 44 20 18 -3 38 -14 45 -23z"/>
<path d="M3773 1134 c-10 -4 -13 -58 -13 -219 0 -185 2 -214 16 -219 9 -3 71
-6 138 -6 132 0 146 5 146 51 0 55 -6 59 -90 59 -84 0 -96 6 -86 46 6 22 11
24 67 24 52 0 61 3 68 22 4 11 6 32 3 45 -4 20 -10 23 -51 23 -74 0 -93 8 -89
39 3 25 5 26 85 29 80 3 83 4 89 29 4 14 3 38 -2 52 l-9 26 -130 2 c-72 1
-136 -1 -142 -3z"/>
<path d="M4667 1133 c-10 -9 -8 -416 2 -431 5 -9 25 -12 57 -10 l49 3 3 73 c3
87 16 92 60 24 17 -26 39 -56 50 -66 20 -17 21 -16 73 53 28 39 56 68 61 65 4
-3 8 -32 8 -64 0 -82 5 -90 54 -90 23 0 47 5 54 12 13 13 18 391 6 422 -9 24
-51 20 -65 -6 -16 -30 -151 -211 -163 -220 -6 -4 -13 -8 -16 -8 -6 0 -145 186
-171 228 -13 21 -48 30 -62 15z"/>
<path d="M692 558 c-12 -12 -18 -201 -7 -230 4 -11 382 -13 2213 -11 1214 1
2215 4 2225 7 15 4 17 18 17 114 0 78 -4 113 -13 120 -20 17 -4418 17 -4435 0z"/>
</g>
</svg>



		</Box >
	);
	if (disabledLink) {
		return <>{logo}</>;
	}

	return <RouterLink to="">{logo}</RouterLink>;
}
